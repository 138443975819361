<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Voucher # <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.voucherNumber"
                  :rules="[v => !!v || 'Voucher is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pay Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.payDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.payDate"
                    :allowed-dates="getAllowedDates()"
                    @input="menuDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="9" lg="9">
            <v-row>
              <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                <v-subheader>Kasbon</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="listSettlementKasbon"
                  item-text="kasbonNumber"
                  return-object
                  v-model="kasbon"
                >
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.kasbonNumber"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="6" md="2" lg="2" class="py-0">
                <v-btn color="success" @click="addKasbon">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Account</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="bank"
                  :items="listBank"
                  item-text="accountName"
                  :filter="filterObject"
                >
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on" @click.stop="addBank(data.item)">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.accountNumber"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Charge</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.bankCharge"
                  :rules="[v => v >= 0 || 'Bank Charge must more than 0']"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Other Account</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="accounts"
                  item-text="accountName"
                  return-object
                  v-model="account"
                  :filter="filterObject"
                >
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on" @click.stop="addAccount(data.item)">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.accountNumber"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"> </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Kasbon <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ items.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.bank="{ item }">
                          {{ item.bank ? item.bank.number : "" }}
                        </template>
                        <template v-slot:item.totalAmount="{ item }">
                          {{ formatPrice(item.totalAmount) }}
                        </template>
                        <template v-slot:item.totalUseAmount="{ item }">
                          {{ formatPrice(item.totalUseAmount) }}
                        </template>
                        <template v-slot:item.totalRemainAmount="{ item }">
                          {{ formatPrice(item.totalRemainAmount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"> </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Bank / Cash <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headerBank"
                        :items="form.banks"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRowBank"
                      >
                        <template v-slot:item.no="{ index }">
                          {{ index + 1 }}
                        </template>
                        <template v-slot:item.amount="{ item }">
                          {{ formatPrice(item.amount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"> </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Account</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headerAccount"
                        :items="form.accounts"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRowAccount"
                      >
                        <template v-slot:item.no="{ index }">
                          {{ index + 1 }}
                        </template>
                        <template v-slot:item.amount="{ item }">
                          {{ formatPrice(item.amount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Kasbon</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Use Kasbon</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalUseAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Remain Kasbon</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalRemainAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-btn color="primary" @click="checkJournal">
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-payment-kasbon
      :item="item"
      :index="index"
      :dialog="dialog"
      @close="close"
      @save="addItem"
      @deleteItem="deleteItem"
      type="settlement"
    ></dialog-payment-kasbon>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="submit"
      @close="close"
      :currency="form.currency"
    ></dialog-review-journal>
    <dialog-bank
      :dialog="dialogBank"
      @save="saveBank"
      @close="close"
      :item="item"
      @deleteBank="deleteBank"
      :index="index"
      :type="type"
    ></dialog-bank>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DialogPaymentKasbon from "@/components/DialogPaymentKasbon";
import DialogReviewJournal from "@/components/DialogReviewJournal";
import DialogBank from "@/components/DialogBank";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "create-payment-general-kasbon",
  mixins: [fiscalMonthMixin],
  components: {
    "dialog-payment-kasbon": DialogPaymentKasbon,
    DialogReviewJournal,
    DialogBank,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      payDate: moment().format("yyyy-MM-DD"),
      voucherNumber: "",
      employeeId: null,
      paymentMethod: "",
      remark: "",
      currency: null,
      accountNumberId: null,
      bankCharge: 0,
      totalAmount: 0,
      totalUseAmount: 0,
      totalRemainAmount: 0,
      items: [],
      banks: [],
      accounts: [],
      status: "Outstanding",
    },
    menuDate: false,
    form: {},
    items: [],
    dialog: false,
    item: {},
    index: -1,
    kasbon: null,
    bank: null,
    account: null,
    listBank: [],
    accounts: [],
    listSettlementKasbon: [],
    listSettlementKasbonTmp: [],
    listSettlementKasbonTmpSecond: [],
    listPaymentMethod: ["Giro", "Cash", "Transfer"],
    valid: true,
    dialogReview: false,
    dialogBank: false,
    edit: false,
    type: "",
    journals: [],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Kasbon Number",
        value: "kasbonNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Kasbon Name",
        value: "kasbonName",
        sortable: false,
        divider: true,
      },
      {
        text: "Division",
        value: "division",
        sortable: false,
        divider: true,
      },
      {
        text: "Method",
        value: "method",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Number",
        value: "bank",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      // {
      //   text: "Requirement",
      //   value: "category",
      //   sortable: false,
      //   divider: true,
      // },
      // {
      //   text: "Description",
      //   value: "description",
      //   sortable: false,
      //   divider: true,
      // },
      // {
      //   text: "Rate",
      //   value: "rate",
      //   sortable: false,
      //   divider: true,
      // },
      {
        text: "Total Amount",
        value: "totalAmount",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Use Amount",
        value: "totalUseAmount",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Remain Amount",
        value: "totalRemainAmount",
        sortable: false,
        divider: true,
      },
    ],
    headerBank: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Number",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
    ],
    headerAccount: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Number",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "Nature",
        value: "nature",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
    ],
  }),

  computed: {
    totalAmount() {
      let total = 0;
      this.items.map(x => {
        total = total + x.totalAmount;
      });
      return total;
    },
    totalUseAmount() {
      let total = 0;
      this.items.map(x => {
        total = total + x.totalUseAmount;
      });
      return total;
    },
    totalRemainAmount() {
      let total = 0;
      this.items.map(x => {
        total = total + x.totalRemainAmount;
      });
      return total;
    },

    ...mapState("paymentSettlementKasbon", ["lastFormNumber", "lastVoucherNumber"]),
    ...mapState("generalKasbon", ["listAccountNumber"]),
    ...mapState("currency", ["dropDownCurrency"]),
    ...mapState("external", ["employees"]),
  },

  watch: {},

  methods: {
    checkJournal() {
      this.journals = [];
      if (this.$refs.form.validate() && this.items.length > 0) {
        if (this.totalAmount > 0) {
          this.journals.push({
            accountNumber: "174.00000",
            accountName: `KASBON ${this.form.currency}`,
            debit: 0,
            credit: this.totalAmount,
          });
        }

        if (this.form.bankCharge > 0) {
          this.journals.unshift({
            accountNumber: "916.00001",
            accountName: `BIAYA ADMINISTRASI BANK`,
            debit: this.form.bankCharge,
            credit: 0,
          });
        }
        this.items.forEach(element => {
          let map = element.items.map(x => {
            return {
              accountNumber: x.accountNumber,
              accountName: x.accountName,
            };
          });
          let array = map.filter(
            (v, i) =>
              map.findIndex(
                x => x.accountName === v.accountName && x.accountNumber === v.accountNumber
              ) === i
          );
          array.forEach(y => {
            let total = 0;
            element.items.forEach(x => {
              if (x.accountName === y.accountName) {
                total += x.totalUseAmount;
              }
            });
            this.journals.unshift({
              accountNumber: y.accountNumber,
              accountName: `${y.accountName}`,
              debit: total,
              credit: 0,
            });
          });
        });
        this.form.banks.forEach(x => {
          if (this.totalAmount > this.totalUseAmount) {
            if (this.totalRemainAmount > 0) {
              this.journals.unshift({
                accountNumber: x.accountNumber,
                accountName: x.accountName,
                debit: x.amount,
                credit: 0,
              });
            }
          } else if (this.totalAmount < this.totalUseAmount) {
            if (this.totalRemainAmount < 0) {
              this.journals.push({
                accountNumber: x.accountNumber,
                accountName: x.accountName,
                debit: 0,
                credit: x.amount,
              });
            }
          }
        });
        this.form.accounts.forEach(element => {
          if (element.nature === "Debit") {
            this.journals.unshift({
              accountNumber: element.accountNumber,
              accountName: `${element.accountName}`,
              debit: element.amount,
              credit: 0,
            });
          } else {
            this.journals.push({
              accountNumber: element.accountNumber,
              accountName: `${element.accountName}`,
              debit: 0,
              credit: element.amount,
            });
          }
        });
        this.journals.forEach(x => {
          this.$store
            .dispatch("chartOfAccount/getPointer", { accountNumber: x.accountNumber })
            .then(response => (x.pointers = response.data));
        });
        this.dialogReview = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    async submit() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.form.formNumber = this.lastFormNumber;
        this.form.totalAmount = this.totalAmount;
        this.form.totalUseAmount = this.totalUseAmount;
        this.form.totalRemainAmount = this.totalRemainAmount;
        this.form.items = this.items;
        this.form.accountNumberId = this.items[0].bank ? this.items[0].bank.id : null;

        let totalDebit = this.form.bankCharge;
        let totalCredit = this.form.totalAmount;
        this.form.items.forEach(x => {
          totalDebit += x.totalUseAmount;
        });
        this.form.banks.forEach(element => {
          if (this.form.totalAmount > this.form.totalUseAmount) {
            totalDebit += element.amount;
          } else if (this.form.totalAmount < this.form.totalUseAmount) {
            totalCredit += element.amount;
          }
        });
        this.form.accounts.forEach(element => {
          if (element.nature === "Debit") {
            totalDebit += element.amount;
          } else {
            totalCredit += element.amount;
          }
        });
        if (totalDebit - totalCredit === 0) {
          this.$store.commit("SET_LOADER", true);
          await this.$store
            .dispatch("paymentSettlementKasbon/create", this.form)
            .then(() => {
              this.clear();
              this.$store.commit("SET_LOADER", false);
            })
            .catch(() => {
              this.$store.dispatch("toast", {
                type: "error",
                message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
              });
              this.$store.commit("SET_LOADER", false);
            });
        } else {
          let different = totalDebit - totalCredit;
          if (different > 0) {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Debit`,
            });
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Dedit`,
            });
          }
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Settlement Kasbon and Account is required",
        });
      }
    },
    deleteItem(index) {
      if (index > -1) {
        this.items.splice(index, 1);
        this.dialog = false;
      }
    },
    addItem(item) {
      if (this.index > 0) {
        this.items[this.index] = item;
      } else {
        this.items.push(this.kasbon);
      }
    },
    deleteBank() {
      if (this.type === "bank") {
        if (this.index > -1) {
          this.form.banks.splice(this.index, 1);
        }
      } else {
        if (this.index > -1) {
          this.form.accounts.splice(this.index, 1);
        }
      }
    },
    close() {
      this.dialog = false;
      this.dialogReview = false;
      this.dialogBank = false;
      this.edit = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    clear() {
      this.items = [];
      this.form = Object.assign({}, this.defaultForm);
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.items.map(x => x).indexOf(item);
      this.dialog = true;
    },
    addKasbon() {
      this.item = this.kasbon;
      this.index = this.items.map(x => x).indexOf(this.kasbon);
      this.form.currency = this.kasbon.currency;
      this.form.paymentMethod = this.kasbon.method;
      if (this.items.indexOf(this.kasbon) > -1) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Kasbon sudah dimasukan pada form",
        });
      } else {
        if (this.items.length > 0) {
          if (this.kasbon.currency !== this.items[0].currency) {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Currency Berbeda",
            });
          } else if (this.kasbon.method !== this.items[0].method) {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Method Berbeda",
            });
          } else {
            this.dialog = true;
          }
        } else {
          this.dialog = true;
        }
      }
    },
    addBank(item) {
      item.amount = Math.abs(this.totalRemainAmount);
      this.item = item;
      this.type = "bank";
      this.dialogBank = true;
    },
    addAccount(item) {
      this.item = item;
      this.type = "other";
      this.dialogBank = true;
    },
    async getBanks() {
      await this.$store.dispatch("chartOfAccount/getBanks").then(response => {
        this.listBank = response.data;
      });
    },
    async getListSettlementKasbon() {
      const response = await this.$store.dispatch("settlementKasbon/getListSettlementKasbon");
      if (response.status === 200) {
        this.listSettlementKasbon = response.data;
      }
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.accountName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.accountNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getListAccountGL");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
    onChangeEmployee(val) {
      this.getListSettlementKasbon(val);
    },
    onChangeMethod(val) {
      if (val === "Cash") {
        this.listSettlementKasbonTmp = this.listSettlementKasbon.filter(x => x.bank === null);
      } else {
        this.listSettlementKasbonTmp = this.listSettlementKasbon.filter(x => x.bank !== null);
      }
    },
    onChangeCurrency(val) {
      this.listSettlementKasbonTmpSecond = this.listSettlementKasbonTmp.filter(
        x => x.currency === val
      );
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        await this.$store.dispatch("paymentSettlementKasbon/getLastFormNumber"),
        await this.$store.dispatch("currency/getListCurrency"),
        await this.$store.dispatch("external/getEmployees"),
        this.getBanks(),
        this.getListSettlementKasbon(),
        this.getAccounts(),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    saveBank() {
      if (this.type === "bank") {
        if (this.edit) {
          this.form.banks[this.index] = this.item;
          this.edit = false;
        } else {
          if (this.form.banks.indexOf(this.item) > -1) {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Account Sudah Digunakan",
            });
          } else {
            this.form.banks.push(this.item);
          }
        }
      } else {
        if (this.edit) {
          this.form.accounts[this.index] = this.item;
          this.edit = false;
        } else {
          if (this.form.accounts.indexOf(this.item) > -1) {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Account Sudah Digunakan",
            });
          } else {
            this.form.accounts.push(this.item);
          }
        }
      }
    },
    onClickRowBank(item) {
      this.item = item;
      this.index = this.form.banks.indexOf(item);
      this.type = "bank";
      this.edit = true;
      this.dialogBank = true;
    },
    onClickRowAccount(item) {
      this.item = item;
      this.index = this.form.accounts.indexOf(item);
      this.type = "other";
      this.edit = true;
      this.dialogBank = true;
    },
  },

  created() {
    this.getInitialData();
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
